
.row
    display: flex
    flex-wrap: wrap
    margin-left: -.75rem
    margin-right: -.75rem


.col-lg-4
    padding-right: .75rem
    padding-left: .75rem
    flex: 0 0 25%
    max-width: 25%

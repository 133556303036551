.btn
    cursor: pointer
    border: none
    padding: .75rem 1rem
    border-radius: 5px
    font-size: 1rem
    font-weight: 500
    display: flex
    align-items: center
    justify-content: center

    svg
        // fill: $white

        &.white

            path
                color: $white 





.btn-success
    background-color: $colorWiwi
    color: $white

    &:hover
        background-color: $colorWiwiActive


    &svg
        &circle
            color: $white




.btn-edit
    background-color: $blue
    color: $white

    &:hover
        background-color: $blueActive

.btn-clone
    background-color: $orangeRest
    color: $orangeActive

    &:hover
        background-color: $orangeActive


.btn-delete
    background-color: $red
    color: $white

    &:hover
        background-color: $redActive



.btn-info
    background-color: $blue-2
    color: $white

    &:hover
        background-color: $blue-2Active



.btn-50
    width: 50%


.btn-lg
    width: 100%


.btn-sm
    padding: .5rem .75rem


.btn-disabled
    opacity: .5
    cursor: none
    pointer-events: none


// .btn-return
//     background-color: $colorWiwi
//     color: $white

//     &:hover
//         background-color: $colorWiwiActive
//

//     &svg
//         &circle
//             color: $white
//
//
//

.btn-option
    background-color: $gray
    color: $white

    &:hover
        background-color: $colorWiwiActive



.btn-copy
    background-color: $black-60
    color: $white

    &:hover
        background-color: $black-60Active



.btn-back
    background-color: $gray
    color: $white

    &:hover
        background-color: $blueActive



.btn-disabled-2
    opacity: .1
    cursor: none
    pointer-events: none

.container-auth {
    min-height: 100vh;
    background: $bgAuth;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.box-auth {
    box-shadow: 0 0 8px 2px $boxShadow;
    background: $white;
    border-radius: 5px;
    padding: 1.5rem 2rem;
    width: 350px;
}

.container-home
    height: calc(100% - 70px)
    position: absolute
    min-height: calc(100% - 70px)
    overflow: auto
    display: flex
    justify-content: center
    width: 100%
    .home-width
        max-width: 600px
        @media screen and (min-width: 900px)
            max-width: 900px
        .home-header
            background: $colorWiwi
            padding: 20px 30px
            .content-section
                display: flex
                justify-content: space-between
                align-items: flex-start
                padding-bottom: 20px
                .header-text
                    h1
                        color: #58595a
                    img
                        padding-top: 0
                        width: 220px
                        height: auto
                img
                    padding-top: 20px
                    width: 40px
                    height: auto
            h2
                color: #575959
                font-weight: 500
                font-size: 18px
                margin: 0
                span
                    font-weight: 600
        .home-body
            display: flex
            flex-direction: column
            gap: 20px
            padding-bottom: 20px
            .home-section
                padding: 0 40px
                display: flex
                flex-direction: column
                gap: 20px
                .img-bus
                    width: 100%
                    max-width: 400px
                    margin: auto
                    height: auto
                .img-time, .img-phone
                    width: 50%
                    height: auto
                    margin: auto
                    padding: 20px 0
                    max-width: 200px
                    margin: auto
                p
                    font-size: 16px
                    text-align: justify
                    .text-1
                        font-weight: 600
                    .text-2
                        background: $colorWiwi
                        font-weight: 600
                    .text-3
                        color: $colorWiwi
                        font-weight: 600
                ul
                    padding: 0 20px 0 40px
                    display: flex
                    flex-direction: column
                    gap: 10px
                    li
                        align-items: center
                        display: flex
                        gap: 10px
                        font-size: 12px
                        &:before
                            background-color: #70d44b
                            border-radius: 100%
                            content: ''
                            flex-shrink: 0
                            display: inline-block
                            height: 10px
                            width: 10px
                h2
                    text-align: center
                    color: $colorWiwi
                .content-btn-special
                    width: 100%
                    display: flex
                    justify-content: center
                    .btn-special
                        width: max-content
                        border-radius: 25px
                        border: 4px solid $white
                        box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.3)
                        background-color: $colorWiwi
                        color: $white
                        padding: .5rem 1rem
                        font-size: 20px
                .content-sub-section
                    display: flex
                    gap: 20px
                    flex-direction: column
                    justify-content: center
                    align-items: center
                    &.col-3
                        @media screen and (min-width: 900px)
                            flex-direction: row
                    .home-sub-section
                        background-color: #eeeeee
                        display: flex
                        gap: 20px
                        flex-direction: column
                        align-items: center
                        justify-content: center
                        padding: 20px
                        width: 210px
                        span
                            color: $colorWiwi
                            font-size: 50px
                            font-weight: 600
                        p
                            font-size: 16px
                            text-align: center
                        img
                            width: 80px
                    .home-sub-section-2
                        background-color: #e0e0e0
                        display: flex
                        flex-direction: column
                        align-items: center
                        justify-content: space-evenly
                        padding: 10px
                        width: 200px
                        height: 200px
                        .content-icon
                            width: 50px
                            height: 50px
                            display: block
                            flex-shrink: 0
                            svg
                                width: 100%
                                height: 100%
                                fill: #707070
                        flex-shrink: 0
                        border-radius: 50%
                        span
                            color: $colorWiwi
                            font-size: 50px
                            font-weight: 600
                            line-height: 0.8
                        p
                            font-size: 16px
                            text-align: center
                            span
                                font-size: 14px
                        .content-icon
                            width: 50px
                            height: 50px
                            display: block
                            flex-shrink: 0
                            svg
                                width: 100%
                                height: 100%
                                fill: #707070
                        .content-icon-unfill
                            width: 50px
                            height: 50px
                            display: block
                            flex-shrink: 0
                            svg
                                width: 100%
                                height: 100%
                                stroke: #707070

            .home-section-2
                background-color: #eeeeee
                padding: 20px 40px
                display: flex
                flex-direction: column
                gap: 20px
                .img-ss
                    width: 60%
                    height: auto
                    margin: auto
                    padding: 20px 0
                    max-width: 200px
                    margin: auto
                p
                    font-size: 16px
                    .text-1
                        font-weight: 600
                    .text-2
                        background: $colorWiwi
                        font-weight: 600
                    &.text-center
                        text-align: center
                    &.text-justify
                        text-align: justify 
            .home-section-3
                background-color: $colorWiwi
                padding: 20px 40px
                display: flex
                flex-direction: column
                gap: 20px
                p
                    font-size: 16px
                    color: $white
                    .text-1
                        font-weight: 600
                        color: $white
                    &.text-center
                        text-align: center
            .actions
                display: flex
                flex-direction: column
                padding: 0 40px
                .button
                    background: transparent
                    border: none
                    display: inline-block
                    font-family: Inter,sans-serif
                    font-size: 16px
                    font-weight: 500
                    height: 60px
                    .button-wrapper
                        background: #606060
                        color: #fff
                        border-bottom: 2px solid rgba(0,0,0,.15)
                        border-radius: 3px
                        display: block
                        padding: 20px 15px

            .infobox
                padding: 0 40px

            .home-section-4
                display: flex
                flex-direction: column
                gap: 40px
                justify-content: center
                @media screen and (min-width: 900px)
                    flex-direction: row
                div
                    display: flex
                    flex-direction: column
                    align-items: center
                    justify-content: center
                    svg
                        width: 120px
                    h2
                        color: #606060
                        font-size: 38px
                        margin-bottom: 5px
                        margin-top: 5px
                        text-transform: uppercase
                        letter-spacing: 0.5px
                    span
                        font-size: 11px
                        color: #999999
                        font-weight: 600
                        text-transform: uppercase
                    p
                        margin-top: 10px
                        font-size: 16px
                        width: 240px
                        text-align: center
                        color: #999999
                        span
                            font-size: 16px
                            color: #606060
                            font-weight: 600
                            text-transform: none
.none
    display: none !important                            
.container-modal
    background: rgba(0, 0, 0, 0.85)
    z-index: 9999
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    
    .show
        top: 25% !important
            
    .modal-main
        position: relative
        min-height: 20vh
        max-height: 50vh
        min-width: calc(100vw - 30%)
        // max-width: 600px
        background: #fff
        border-radius: 8px
        padding: 20px
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25)
        position: absolute
        top: -100%
        transition: top 0.5s ease-in-out

        h1
            font-size: 1.5em
            color: #333
            margin-bottom: 10px

        .modal-header
            font-size: 1.2em
            font-weight: bold
            margin-bottom: 10px

        .modal-content select
            width: 100%
            padding: 8px
            font-size: 1em
            border: 1px solid #ccc
            border-radius: 4px
            outline: none
@media screen and (min-width: 900px)
    .modal-main 
        width: 630px !important
        min-width: 630px !important
  



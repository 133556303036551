.d-none
    display: none !important


.d-block
    display: block


.d-inline
    display: inline


.d-flex
    display: flex


.justify-content-center
    justify-content: center


.justify-content-between
    justify-content: space-between


.justify-content-around
    justify-content: space-around


.align-items-center
    align-items: center


.align-items-end
    align-items: flex-end


.flex-wrap
    flex-wrap: wrap

$bgAuth: #F2F2F2
$colorWiwi: #70D44B
$colorWiwiActive: #63CB3D

$white: #FFFFFF

// $black-30: #303230
$black-60: #666666
$black-60Active: #777777
$black: #000000

$gray: #333333
$gray-70: #c3c3c3
$gray-60: #EEEEEE
$gray-50: #CCCCCC
$gray-20: #CED4DA
$gray-10: #F9F9F9
// $gray-10: #FAFAFA

$blue: #3798FF
$blueActive: #2388F4

$cian: #c5edf3
$cian-10: #d4e4fa
$orange: #fde1be
$orangeRest: #f8ae54
$orangeActive: #ff9c23

$blue-90: #002bb8
$blue-90Active: #1843cf

$blue-2: #26c0e6
$blue-2Active: #45d0f3

$red: #F11818
// $red: #F1394B
$redActive: #E92B3E

$yellow: #FFD700
$yellowActive: #DEC011

$boxShadow: rgba(0, 0, 0, 0.08)
$bgModal: rgba(0, 0, 0, 0.1)

$rgb-black-15: rgba(0,0,0,0.15)

$spacing1: .25rem
$spacing2: .5rem
$spacing3: 1rem
$spacing4: 1.5rem
$spacing5: 3rem

$mini: 375px
// $mobile
// $tablet-mini
// $tablet
// $desktop-mini
// $desktop
@font-face
    font-family: 'Helvetica Neue'
    src: url('./fonts/HelveticaNeue/HelveticaNeue-Italic.woff2') format('woff2'), url('./fonts/HelveticaNeue/HelveticaNeue-Italic.woff') format('woff')
    font-weight: normal
    font-style: italic


@font-face
    font-family: 'Helvetica Neue'
    src: url('./fonts/HelveticaNeue/HelveticaNeue-Bold.woff2') format('woff2'), url('./fonts/HelveticaNeue/HelveticaNeue-Bold.woff') format('woff')
    font-weight: bold
    font-style: normal


@font-face
    font-family: 'Helvetica Neue'
    src: url('./fonts/HelveticaNeue/HelveticaNeue-Light.woff2') format('woff2'), url('./fonts/HelveticaNeue/HelveticaNeue-Light.woff') format('woff')
    font-weight: 300
    font-style: normal


@font-face
    font-family: 'Helvetica Neue'
    src: url('./fonts/HelveticaNeue/HelveticaNeue-Medium.woff2') format('woff2'), url('./fonts/HelveticaNeue/HelveticaNeue-Medium.woff') format('woff')
    font-weight: 500
    font-style: normal


@font-face
    font-family: 'Helvetica Neue'
    src: url('./fonts/HelveticaNeue/HelveticaNeue-LightItalic.woff2') format('woff2'), url('./fonts/HelveticaNeue/HelveticaNeue-LightItalic.woff') format('woff')
    font-weight: 300
    font-style: italic


@font-face
    font-family: 'Helvetica Neue'
    src: url('./fonts/HelveticaNeue/HelveticaNeue.woff2') format('woff2'), url('./fonts/HelveticaNeue/HelveticaNeue.woff') format('woff')
    font-weight: normal
    font-style: normal


// Gotham Narrow

@font-face
    font-family: 'Gotham Narrow'
    src: url('./fonts/GothamNarrow/GothamNarrow-Black.woff2') format('woff2'), url('./fonts/GothamNarrow/GothamNarrow-Black.woff') format('woff')
    font-weight: 900
    font-style: normal
    font-display: swap


@font-face
    font-family: 'Gotham Narrow'
    src: url('./fonts/GothamNarrow/GothamNarrow-Bold.woff2') format('woff2'), url('./fonts/GothamNarrow/GothamNarrow-Bold.woff') format('woff')
    font-weight: bold
    font-style: normal



@font-face
    font-family: 'Gotham Narrow'
    src: url('./fonts/GothamNarrow/GothamNarrow-Book.woff2') format('woff2'), url('./fonts/GothamNarrow/GothamNarrow-Book.woff') format('woff')
    font-weight: normal
    font-style: normal


*
    font-family: 'Helvetica Neue'
    padding: 0
    margin: 0
    outline: 0 none
    box-sizing: border-box
    color: $gray

::-webkit-scrollbar
    width: 10px

::-webkit-scrollbar-track
    background: transparent

::-webkit-scrollbar-thumb
    background: $gray-70

::-webkit-scrollbar-thumb:hover
    background: $gray

a
    text-decoration: none

//COLORS
$green: #71d44c

.cursor-pointer
    cursor: pointer


ul
    list-style: none

h1, h2, h3, h4, h5, h6
    color: #111
    text-transform: none
    line-height: 100%
    letter-spacing: -1px

h2
    font-size: 36px
    margin: 20px 0
    font-family: "Gotham Narrow", sans-serif
h3
    font-size: 28px
    margin: 15px 0
    font-weight: 500
    font-family: "Helvetica Neue", sans-serif
    color: #999
h4
    font-size: 18px
    font-weight: 500
    text-transform: uppercase
    margin: 10px 0
    font-family: "Helvetica Neue", sans-serif

p, label, li, input, textarea, span, small
    font-family: "Helvetica Neue", sans-serif
    line-height: 120%
    font-weight: 400
    color: #606060

ol
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 10px 0
    counter-reset: item
    list-style-type: none
    padding-left: 0
    margin-top: 20px

    > li
        text-align: left
        counter-increment: item
        width: 100%
        
        p
            padding: 0 0 5px 33px
            line-height: 120% !important

        &::before
            // content: counters(item, '.') '.  '
            // color: #71d44c
            // font-weight: bold
            content: counters(item, ".") ". "
            color: #fff
            font-weight: bold
            display: inline-block
            float: left
            background: #71d44c
            padding: 2px 5px
            margin-right: 10px
            border-radius: 3px

        ol
            padding-left: 25px


.link
    color: $blue
    font-weight: 600
    cursor: pointer


.tooltip
    position: relative
    display: inline-block
    width: 100%

    span
        visibility: hidden
        position: absolute
        z-index: 1
        color: $white
        font-size: .9rem
        background-color: $black-60
        border-radius: 5px
        padding: .5rem .75rem
        display: ruby


    &:hover
        span
            visibility: visible
            opacity: 1



    &.top
        span
            bottom: -40px
            left: 0

.g-5
    gap: 5px
.g-10
    gap: 10px
.g-15
    gap: 15px
.g-20
    gap: 20px

// #chat24-root

// .startBtn::part(button)
//     bottom: 86px !important

// :host.startBtn
//     bottom: 86px !important
.footer
    .options
        .btn
            padding: 0 10px
            label
                font-weight: bold
                color: #ccc
            &.active
                label
                    color: $green

.inline-icon
    display: inline-block
    width: 25px
    height: 25px

.container-img-fluid
    width: 400px
    height: auto
    display: flex
    padding: 20px 0
    .img-fluid
        width: 100%
        height: 100%

//pagina inicio
.main-container
    flex-direction: column
    justify-content: center
    align-items: center
    padding-top: 20px
    width: 100%
    height: 100%
    font-family: "Gotham Narrow" !important


.logo-container 
    display: flex
    margin-top: 20px
    align-items: center
    justify-content: center

.logo-container svg
    width: 100px
    height: 100px
    fill: none

.logo-container p
    font-size: 20px

.todos-container 
    margin-top: -5px
    display: flex
    align-items: center
    justify-content: center

.todos-container p
    font-size: 16px

.internet-container
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 10px

.internet-container p 
    color: #71d44c !important
    font-size: 32px

.sel-ciudad
    display: flex
    align-items: center
    justify-content: center

.sel-ciudad p
    // margin-top: 20px
    font-size: 24px

.main-content
    display: flex
    justify-content: center
    align-items: center
    padding-top: 20px
    width: 100%
    height: 100%

.title-container
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding-top: 20px
    width: 100%
    height: 100%

.cards-container
    display: flex
    flex-wrap: wrap
    justify-content: center
    margin-top: 20px
    padding: 10px
    gap: 25px

.card-ciudad
    width: 80px
    height: 80px
    border: 1px solid #ccc
    border-top-left-radius: 10px
    border-top-right-radius: 10px
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25)
    background-color: #fff
    align-items: center
    justify-content: center
    display: inline-block
    vertical-align: top
    cursor: pointer

    img
        border-radius: 10px
        padding: 5px

.card-ciudad:hover 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25)

.ciudad
    font-size: 14px
    background-color: #000000
    color: #fff
    padding: 5px
    text-align: center
    border-bottom-left-radius: 10px
    border-bottom-right-radius: 10px

.card-ciudad svg
    padding: 20px
    // width: 25px
    // height: 25px
    // margin: 10px

.yovoy
    width: 60px !important
    margin-top: 5px !important
    // height: 5px !important
.form-input
    display: block
    padding: .5rem .75rem
    border-radius: 5px
    border: 1px solid $gray-20
    margin-bottom: 1.5rem
    width: 100%
    font-size: 1rem



.form-select
    display: block
    padding: .5rem .75rem
    border-radius: 5px
    border: 1px solid $gray-20
    margin-bottom: 1.5rem
    width: 100%
    font-size: 1rem
    background-color: $white



.form-input-pasword
    display: flex
    align-items: center
    border: 1px solid $gray-20
    border-radius: 5px
    margin-bottom: 1.5rem
    width: 100%

    input
        border: none
        padding: .5rem .75rem
        width: 100%
        font-size: 1rem
        border-radius: 5px


    svg
        cursor: pointer
        margin: 0 .75rem


    .active-eye
        fill: $colorWiwi




.form-input-color
    display: flex
    align-items: center
    border: 1px solid $gray-20
    border-radius: 5px
    margin-bottom: 1.5rem
    width: 100%

    .input
        border: none
        padding: .5rem .75rem
        width: 100%
        font-size: 1rem
        border-radius: 5px


    .color
        border: none
        margin: 0 .75rem
        font-size: 1rem
        cursor: pointer



.form-input-checkbox

    display: flex
    flex-wrap: wrap

    label
        padding: .2rem .5rem
        border: 1px solid $gray
        border-radius: 5px
        color: $gray
        cursor: pointer
        margin: 0 .5rem .5rem 0

        &:hover
            border: 1px solid $colorWiwi
            background-color: $colorWiwi
            color: $white


        // &.active
        //     border: 1px solid $colorWiwi
        //     background-color: $colorWiwi
        //     color: $white



    input
        display: none

        &:checked + label
            background-color: $colorWiwiActive
            color: $white
            border: 1px solid $colorWiwiActive




.input-switch
    display: flex
    border-radius: 5px
    border: 1px solid $gray-20
    width: fit-content

    button
        cursor: pointer
        border: none
        padding: .3rem .6rem
        font-size: 1rem
        font-weight: 500
        display: flex
        align-items: center
        justify-content: center
        background-color: $white

        &.left
            border-top-left-radius: 5px
            border-end-start-radius: 5px
            border-right: 1px solid $gray-20


        &.right
            border-top-right-radius: 5px
            border-end-end-radius: 5px
            border-left: 1px solid $gray-20


        &.active
            background-color: $colorWiwiActive
            color: #FFFFFF





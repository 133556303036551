.mb-1
    margin-bottom: $spacing1


.mb-2
    margin-bottom: $spacing2


.mb-3
    margin-bottom: $spacing3


.mb-4
    margin-bottom: $spacing4


.mb-5
    margin-bottom: $spacing5


.mr-1
    margin-right: $spacing1


.mr-2
    margin-right: $spacing2


.mr-3
    margin-right: $spacing3


.mr-4
    margin-right: $spacing4


.mr-5
    margin-right: $spacing5


.mt-1
    margin-top: $spacing1


.mt-2
    margin-top: $spacing2


.mt-3
    margin-top: $spacing3


.mt-4
    margin-top: $spacing4


.mt-5
    margin-top: $spacing5


.ml-1
    margin-left: $spacing1


.ml-2
    margin-left: $spacing2


.ml-3
    margin-left: $spacing3


.ml-4
    margin-left: $spacing4


.ml-5
    margin-left: $spacing5


.mx-auto
    margin-left: auto
    margin-right: auto










.w-100
    width: 100%


.w-70
    width: 70%


.w-50
    width: 50%


.w-40
    width: 40%


.w-30
    width: 30%


.w-20
    width: 20%







.gap-10
    gap: 10px


.gap-15
    gap: 15px


.gap-20
    gap: 20px

.container-sm
    max-width: 600px
    margin: auto

.container-lg
    max-width: 1400px
    margin: auto


.container-main
    background-color: $bgAuth
    min-height: calc(100vh - 56px)
    padding: 1rem
    overflow-x: hidden


.container-main-header
    display: flex
    align-items: center
    justify-content: space-between


.container-ayuda
    height: calc(100vh - 155px)
    max-width: 600px
    margin: auto

    .content
        padding: 20px
        margin: 71px 0 0 0
        height: 100%
        overflow-y: auto

    .option-container
        max-width: 600px
        margin: auto
        background-color: $white
        position: absolute
        bottom: 0
        left: 0
        right: 0
        padding: 20px
        display: flex
        align-items: center
        justify-content: space-between

// .container-ayuda
//     max-width: 600px
//     margin: auto
//     height: calc(100vh - 110px)
//     margin: 90px auto 0 auto
//     padding: 0 20px
//     max-width: 600px
//     box-sizing: border-box

//     .content
//         position: relative
//         height: 100%

//         svg
//             display: block
//             width: 20px
//             height: 20px
//             path
//                 stroke: #fff
//                 fill: #fff

// @media screen and (max-width: 1200px)
//     .container-ayuda
//         height: calc(100vh - 160px)


// .option-container
//     position: absolute
//     right: 0
//     left: 0
//     bottom: 0
//     display: flex
//     justify-content: space-between
//     align-items: center

//     .btn
//         font-size: 12px

//     &.mini
//         display: none


//     @media screen and (max-width: $mini)

//         &.mini
//             display: block


//         &.mobile
//             display: none


    // @media screen and (min-width: $small) and (max-width: $medium)
    //     //css code
    //



.editor-container
    border: 1px solid $gray-20
    border-radius: 5px
    margin-bottom: 1.5rem

    .keys-container
        background-color: $gray-10
        border-top-left-radius: 5px
        border-top-right-radius: 5px
        border-bottom: 1px solid $gray-20
        padding: .5rem
        display: flex
        flex-wrap: wrap
        gap: 10px

        span
            font-weight: 500
            cursor: pointer
            border: 1px solid $gray-20
            padding: .15rem .8rem
            border-radius: 5px
            background-color: $white

            &.active
                background-color: $blueActive
                color: $white
                border: 1px solid $blueActive


            &:hover
                background-color: $blueActive
                color: $white
                border: 1px solid $blueActive




    .editor
        padding: .5rem .75rem
        min-height: 300px

        .ProseMirror
            min-height: 284px

            a
                text-decoration: underline
                color: #0066cc

            ul, ol
                padding-left: 2.5rem

            img
                width: 100%

                &.img-inline
                    display: inline-flex
                    align-items: center
                    justify-content: center
                    width: 20px
                    height: 20px
                    margin: 0 0 -3px 0


.messge-container
    background-color: $cian
    padding: 1.25rem
    margin-bottom: 2rem

    h2
        &:first-child
            margin-top: 0


.question-container
    background-color: $orange
    padding: 1.25rem
    // margin-bottom: 2rem

    h2
        &:first-child
            margin-top: 0

.container-phone
    position: absolute
    // top: 70px
    right: 0
    left: 0
    max-width: 600px
    margin: auto
    padding: 0 0 20px 0
    min-height: calc(100% - 70px)

    &.container-phone-intro
        height: calc(100% - 70px)

    .intro
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        padding: 20px 0

        .content
            width: 100%
            height: 100%

            .head
                height: 100%
                //padding-top: 20px
                text-align: center

                img
                    margin-bottom: 20px
                    width: 200px
                    padding: 0 20px

                h1
                    font-size: 36px
                    margin-bottom: 20px
                    line-height: 100%
                    color: #333
                    text-align: center
                    padding: 0 20px

                .content-text
                    padding: 0 20px
                    height: calc(100% - 80px)
                    overflow-y: auto

                    .infobox
                        padding-bottom: 20px
                        &.quick-connection
                            padding-bottom: 0
                    .actions
                        display: flex
                        flex-direction: column
                        gap: 10px
                        margin: 20px 0
                        .button
                            font-family: Inter,sans-serif
                            font-size: 16px
                            font-weight: 500
                            height: 60px
                            line-height: 120%
                            background: transparent
                            border: none
                            display: inline-block
                            margin-bottom: 20px
                            .button-wrapper
                                background: #eee
                                border-bottom: 2px solid rgba(0,0,0,.15)
                                border-radius: 3px
                                color: #444
                                display: block
                                padding: 20px 15px
                            &.primary
                                &:hover .button-wrapper
                                    background: #53b130
                                .button-wrapper
                                    background: #71d44c
                                    color: #fff
                            &.important
                                &:hover .button-wrapper
                                    background: #444
                                .button-wrapper
                                    background: #606060
                                    color: #fff
                            &:active .button-wrapper
                                border-bottom-color: transparent
                                border-top: 2px solid rgba(0,0,0,.15)
                                padding: 16px 15px 18px
                                transform: translateY(2px)
                            &:hover
                                cursor: pointer

                    p
                        line-height: 1.5
                        text-align: left
                        padding-bottom: 15px

                        &:last-of-type
                            padding-bottom: 0

                    a
                        color: $green
                        font-weight: bold

            .options
                display: flex
                gap: 15px

                .option
                    gap: 10px
                    width: 50%
                    margin-bottom: 15px
                    height: 65px
                    justify-content: start

                    .icon
                        fill: $white
                        min-width: 20px
                        min-height: 20px

                    p
                        color: $white

            @media screen and (max-width: 500px)
                .options
                    display: block

                    .option
                        width: 100%
                        height: auto

    .diagnostics
        height: 100%
        overflow-y: auto
        padding: 20px 20px 0
        margin-top: 70px

        h2
            margin-top: 0

        .phone-summary
            margin: 0 0 20px 0
            display: flex
            flex-direction: column
            list-style: none
            border: 1px solid $gray-50
            overflow: hidden
            background: $white
            border-radius: 5px
            box-sizing: border-box

            li
                width: 100%
                padding: 10px
                border-bottom: 1px solid $gray-50

                &:last-of-type
                    border-bottom: none

        .results
            .list-of-problems
                list-style: none
                display: flex
                flex-direction: column
                gap: 5px

                li
                    display: flex
                    flex-direction: row
                    gap: 5px
                    align-items: flex-start
                    justify-content: flex-start

                    &:before
                        content: ""
                        display: inline-block
                        min-width: 15px
                        height: 15px
                        border-radius: 100%
                        margin-top: 2px

                    &.urgent
                        &:before
                            background: $red

                    &.minor
                        &:before
                            background: $yellow

                    &.minor
                        &:before
                            background: $yellow

                    &.mild
                        &:before
                            background: $colorWiwi

    .suggestions
        height: 100%
        padding: 20px
        margin-top: 70px

        .title
            margin-top: 0

        .body
            overflow-y: auto
            height: calc(100vh - 320px)

        .body2
            overflow-y: auto
            height: calc(100vh - 180px)

        .footer-suggestions
            padding: 10px 0 0 0

    @media screen and (max-width: 500px)

        .suggestions

            .title
                font-size: 26px

    .problems
        height: 100%
        padding: 20px
        margin-top: 70px

        .head

            h2
                color: $black-60
                margin-top: 0

            .search
                padding: 0 10px
                background-color: $white
                border: 1px solid $gray-50
                margin-bottom: 15px
                border-radius: 3px
                box-sizing: border-box
                display: flex
                align-items: center
                justify-content: space-between

                input
                    border: none
                    padding: 10px 0
                    width: 100%
                    border-radius: 3px
                    font-size: 16px

        .list-of-problems
            position: absolute
            // bottom: 20px
            top: 227px
            left: 20px
            right: 20px
            height: calc( 100% - 240px )
            // height: calc(100vh - 274px)
            overflow-y: auto

            li
                width: 100%

                &:last-of-type
                    .content-tags
                        border-bottom: none

                .content-tags
                    width: 100%
                    display: flex
                    flex-direction: column
                    justify-content: flex-start
                    align-items: flext-start
                    gap: 5px 0
                    padding: 5px 0
                    border-bottom: 1px solid $gray-50

                    &:hover
                        background: #eee

            &.size-2
                top: 130px
                height: calc( 100% - 130px )

        .tags
            display: flex
            gap: 5px
            flex-wrap: wrap

            span
                background: $white
                font-weight: 500
                font-size: .7rem
                border-radius: 5px
                text-transform: uppercase
                padding: .3rem

    @media screen and (max-width: 500px)

        .problems
            .head
                h2
                    font-size: 26px


.container-requirements
    min-height: calc(100vh - 56px)
    background: $bgAuth
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 25px

    .box
        background: $white
        border-radius: 5px
        padding: 1.5rem 2rem
        width: 600px

        .content
            display: flex
            gap: 20px

            .title
                font-weight: 500
                min-width: 70px


            .tags
                display: flex
                gap: 10px
                flex-wrap: wrap

                li
                    font-size: 0.8rem
                    padding: .2rem .5rem
                    border: 1px solid $gray
                    border-radius: 5px
                    color: $gray
                    cursor: pointer

                    &:hover
                        border: 1px solid $colorWiwi
                        background-color: $colorWiwi
                        color: $white


                    &.active
                        border: 1px solid $colorWiwi
                        background-color: $colorWiwi
                        color: $white

//FOOTER NAV
.footer
    position: fixed
    left: 0
    right: 0
    bottom: 0
    width: 100%
    border-top: 1px solid #ccc
    background: #fff
    .container
        padding: 10px 20px
        box-sizing: border-box
    .options
        display: flex
        align-items: center
        justify-content: space-between
        gap: 20px
        button
            display: flex
            flex-direction: column
            gap: 3px
            padding: 10px
            background: transparent

            label
                text-transform: uppercase
                font-size: 12px

            svg
                width: 30px
                height: 30px
                display: block
                > *
                    fill: #ccc
            &.active
                svg
                    > *
                        fill: $colorWiwi
            &:hover
                background: #f9f9f9




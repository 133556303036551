.navbar
    padding: 1.25rem 0
    border-bottom: 1px solid $gray-50
    background-color: $white
    position: fixed
    top: 0
    right: 0
    left: 0
    z-index: 99

    .wrapper
        justify-content: space-between
        padding: 0 20px
    &.active
        bottom: 0
        height: 100%
        .nav-button

        .navbar-menu
            display: flex
            height: calc(100% - 30px)
            padding: 0

    .header-title
        padding: 0
        margin: 0
        font-size: 24px

    .nav-button
        display: flex
        justify-content: center
        align-items: center


        .menu
            display: flex
            justify-content: center
            align-items: center
            font-family: sans-serif

        .icon
            fill: $black
            width: 30px
            height: 30px

        .btn
            padding: 7px

            .icon
                fill: $white
                width: auto
                height: auto

                path
                    color: $white

    .navbar-menu
        padding-top: 1.25rem
        display: none
        justify-content: center
        align-items: center
        .content-item
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            li
                cursor: pointer
                text-align: center
                padding: 20px
                font-weight: 500
                font-size: 18px

                &:hover
                    color: $colorWiwi


                &.active
                    color: $colorWiwiActive






.navbar-admin
    padding-left: 1rem
    padding-right: 1rem
    width: 100%
    box-shadow: 0 0 8px 2px $boxShadow

    & .admin-menu
        margin-top: 1rem
        margin-bottom: 1rem
        cursor: pointer

        &:hover
            fill: $colorWiwi

.wrapper
    display: flex
    align-items: center
    justify-content: space-between



.navbar-item
    display: flex
    padding: 1rem
    font-weight: 500
    align-items: center

    &:hover
        background-color: $gray-10
        color: $colorWiwi

        & svg
            fill: $colorWiwi



    &.active
        color: $colorWiwi

        & svg
            fill: $colorWiwi




.card
    background-color: $white
    border-radius: 5px
    border: 2px solid $boxShadow
    padding: .75rem 1.25rem
    display: flex
    align-items: center
    justify-content: space-between
    font-weight: 500
    margin-bottom: 1rem

    .container-title
        display: flex
        align-items: center
        // width: 44%


    .container-categoria
        display: flex
        align-items: center
        justify-content: flex-start
        width: 44%
        flex-wrap: wrap


    .container-action
        display: flex




.basic-card
    background-color: $white
    border-radius: 5px
    border: 2px solid $boxShadow
    padding: .75rem 1.25rem
    font-weight: 500
    margin-bottom: 1rem


.card-width-50
    width: 50%


.card-width-40
    width: 40%


.card-width-30
    width: 30%


.card-width-20
    width: 20%


.categoria-item
    // border: 1px solid $colorWiwi
    border-radius: 5px
    padding: .25rem .5rem
    margin: .2rem 0
    margin-right: .75rem
    color: $white
    background-color: $colorWiwi


.card-categoria
    background-color: $white
    border-radius: 5px
    border: 2px solid $boxShadow
    padding: .75rem 1.25rem
    display: flex
    align-items: center
    justify-content: space-between
    font-weight: 500
    margin-bottom: 1rem


.container-item
    display: flex
    align-items: center

    & .item
        border-radius: 5px
        padding: .2rem .5rem
        margin: .2rem 0
        margin-right: .75rem
        color: $white

        &.positive
            border: 1px solid $colorWiwi
            background-color: $colorWiwi

            &:hover
                border: 1px solid $colorWiwiActive
                background-color: $colorWiwiActive



        &.negative
            border: 1px solid $red
            background-color: $red

            &:hover
                border: 1px solid $redActive
                background-color: $redActive



        &.return
            border: 1px solid $blue
            background-color: $blue

            &:hover
                border: 1px solid $blueActive
                background-color: $blueActive




    & .check
        fill: $colorWiwi


    & .times
        fill: $red



.card-scroll-active
    box-shadow: 0 0 8px 1px $colorWiwiActive
    border: 2px solid $colorWiwiActive

.more
    color: $blue


// .container-troubleshooting-link
//     display: flex
//     flex-direction: column
//     align-items: flex-start
//

// .troubleshooting-item
//     border-radius: 5px
//     padding: .2rem .5rem
//     margin: .2rem 0
//     margin-right: .75rem
//     color: $white

//     &.positive
//         border: 1px solid $colorWiwi
//         background-color: $colorWiwi
//

//     &.negative
//         border: 1px solid $red
//         background-color: $red
//

//     &.back
//         border: 1px solid $blue
//         background-color: $blue
//

//
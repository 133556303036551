.modal
    position: fixed
    display: none
    justify-content: center
    align-items: center
    flex-direction: column
    overflow: hidden
    top: 0
    left: 0
    right: 0
    bottom: 0
    padding: 0
    z-index: 99
    // padding: 5px

    &.active
        display: flex


    .modal-container
        background-color: $bgModal
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0


    .modal-content
        display: flex
        flex-direction: column
        max-height: 100%
        max-width: 600px
        width: 100%
        position: relative
        background-color: $white
        border-radius: 5px
        inset: 0

        .modal-header
            display: flex
            align-items: center
            justify-content: space-between
            padding: 1rem 1.5rem
            background-color: $gray-10
            border-bottom: 1px solid $boxShadow



        .modal-body
            padding: 1.5rem
            overflow-x: hidden
            overflow-y: auto
            scrollbar-width: thin

            .modal-editor
                img
                    width: 100%

                    &.img-inline
                        display: inline-flex
                        align-items: center
                        justify-content: center
                        width: 20px
                        height: 20px
                        margin: 0 0 -3px 0

        ::-webkit-scrollbar
            width: 10px

        /* Track */
        ::-webkit-scrollbar-track
            background: transparent
        
        /* Handle */
        ::-webkit-scrollbar-thumb
            background: $gray-60

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover
            background: $gray

        .modal-footer
            display: flex
            align-items: center
            justify-content: space-between
            padding: 1rem 1.5rem
            background-color: $gray-10
            border-bottom: 1px solid $boxShadow

            .link
                color: $colorWiwi


        ul
            padding-left: 2.5rem

        img
            width: 100%
            display: block
            object-fit: contain
            height: auto
            margin: 20px 0

        iframe
            width: 100%
            display: block
            object-fit: contain
            height: auto
            margin: 20px 0
            border:none

    @media screen and (max-width: 600px)
        

        // @media screen
        //     @media (max-width: 1024px)
        //         margin: 75px auto 5px auto



    .modal-times
        button
            width: 30px
            height: 30px
            padding: 7px
            .icon
                fill: #FFFFFF
                width: auto
                height: auto



